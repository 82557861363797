import React, { ReactNode, useEffect, useState } from 'react';
import './styles.scss';

interface Props {
  children: ReactNode;
  customBackground?: ReactNode;
  style?: Record<any, any>;
}

const AppLayout: React.FC<Props> = ({
  children,
  customBackground,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      <div>
        <img
          src={
            customBackground || require('../../assets/images/background.webp')
          }
          alt="Background"
          className="app__background"
          onLoad={() => setIsLoaded(true)}
          {...props}
        />
      </div>
      <div className="app__content" data-loaded={isLoaded}>
        {children}
      </div>
    </>
  );
};

export default AppLayout;
