import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showCloseButton?: boolean; // Пропс для показа/скрытия кнопки закрытия
  className?: string;
  isOutsideDisabled?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  showCloseButton = true,
  className = '',
  isOutsideDisabled = false,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className={`modal-overlay ${className}`}
      onClick={() => !isOutsideDisabled && onClose()}
    >
      <div
        className="modal-content"
        onClick={(e) => !isOutsideDisabled && e.stopPropagation()}
      >
        {showCloseButton && (
          <button className="close-button" onClick={onClose}>
            ✖
          </button>
        )}
        {children}
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
