import React from 'react';
import './styles.scss';
import coinImage from '../../assets/images/coin.png';
import bearLogo from '../../assets/images/bear-logo.png';
import honeyCoinImage from '../../assets/images/honey-coin.webp';
// import { useNoise } from '../../../../components/Header/hooks';

const Header: React.FC<{ coins: number; tonCoins: number }> = ({
  coins = 0,
  tonCoins = 0,
}) => {
  // const { showNoise } = useNoise();

  return (
    <header className="app-header">
      <div className="coin-container__wrapper">
        <div className="coin-container">
          <div className="coin-container__image">
            <img src={coinImage} className="coin-container__coin " alt="coin" />
            <div className="coin-container__coin-effect"></div>
          </div>
          <p className="coin-container__count">{coins.toFixed(9)}</p>
        </div>
        <div className="coin-container coin-container_ton">
          <div className="coin-container__image">
            <img
              src={honeyCoinImage}
              className="coin-container__coin"
              alt="coin"
            />
            <div className="coin-container__coin-effect coin-container__coin-effect_blue"></div>
          </div>
          <p className="coin-container__count">{tonCoins.toFixed(9)}</p>
        </div>
      </div>
      <div className="logo">
        <img src={bearLogo} className="logo__image" alt="logo" />
        {/*{showNoise && <div className="logo__noise"></div>}{' '}*/}
      </div>
    </header>
  );
};

export default Header;
