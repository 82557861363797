import React, { useEffect } from 'react';
import './styles.scss';
import Header from './components/Header';
import AppLayout from '../../components/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import BackButton from '../../components/BackButton';
import HiveButton, { HiveData } from '../../pages/Game/components/HiveButton';
import { setHiveCoins } from '../../store/coinsSlice';
import SuccessModal from './components/SuccessModal';
import { HIVE_DATA } from './constants';

const MainPage: React.FC = () => {
  const dispatch = useDispatch();
  const hiveCoins = useSelector((state: RootState) => state.coins.hiveCoins);

  const handleHiveAdd = (hive: HiveData) => {
    dispatch(setHiveCoins(hiveCoins + Number(hive?.reward)));
  };

  const hiveData = HIVE_DATA;

  useEffect(() => {
    dispatch(setHiveCoins(0));
  }, []);

  return (
    <AppLayout
      customBackground={require('../../assets/images/honey-game-background.jpeg')}
      style={{ height: '100vh' }}
    >
      <>
        <BackButton />
        <Header coins={hiveCoins} maxHiveCoins={hiveData?.length} />
        {hiveData?.map((hive) => {
          return (
            <HiveButton key={hive?.id} hive={hive} onClick={handleHiveAdd} />
          );
        })}
      </>
      <SuccessModal
        hiveCoins={hiveCoins}
        maxHiveCoins={hiveData?.length}
        isSuccess={hiveCoins === hiveData?.length}
      />
    </AppLayout>
  );
};

export default MainPage;
