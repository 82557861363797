import axios from './api/axios';

// Функция для отправки данных на сервер
export const handlePayment = async ({
  uniqueHash,
  developerWalletAddress,
  value,
}: {
  uniqueHash: string;
  developerWalletAddress: string;
  value: string;
}) => {
  const response = await axios.post('/wallet/transaction', {
    uniqueHash,
    developerWalletAddress,
    value,
  });

  return response.data;
};
