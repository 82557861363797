import React, { useEffect, useState } from 'react';
import './styles.scss';
import Button from '../../../../components/Button';
import HiveFullImage from '../../../../assets/images/hive-full.webp';
import HiveEmptyImage from '../../../../assets/images/hive-empty.webp';

export enum HiveButtonTypes {
  Full = 'full',
  Empty = 'empty',
}

export interface HiveData {
  type: HiveButtonTypes;
  position: { x: number; y: number };
  id: string | number;
  scale: number;
  hp: number;
  reward?: number;
}

export interface HiveButtonProps {
  hive: HiveData;
  onClick: (hive: HiveData) => void;
}

const HiveButton: React.FC<HiveButtonProps> = ({ hive: hiveData, onClick }) => {
  const [hive, setHive] = useState(hiveData);
  const [isDamaged, setIsDamaged] = useState(false);

  const image: Record<HiveButtonTypes, string> = {
    [HiveButtonTypes.Full]: HiveFullImage,
    [HiveButtonTypes.Empty]: HiveEmptyImage,
  };

  useEffect(() => {
    setHive(hiveData);
  }, [hiveData]);

  const handleClick = () => {
    setIsDamaged(true);
    setTimeout(() => setIsDamaged(false), 100);
    if (hive.hp > 0) {
      setHive((prev) => {
        const newHp = Math.max(prev.hp - 5, 0); // Ограничиваем hp до 0
        if (newHp < 1) {
          onClick(hive);
        }
        return {
          ...prev,
          hp: newHp,
          type: newHp === 0 ? HiveButtonTypes.Empty : prev.type,
        };
      });
    }
  };

  return (
    <div
      className="hive-button__wrapper"
      data-active={hive.type === HiveButtonTypes.Full}
      style={{
        transform: `scale(${hive.scale})`,
        top: hive.position.y,
        left: hive.position.x,
      }}
    >
      <div
        className="health-bar"
        data-damaged={isDamaged}
        style={{
          width: `${(hive.hp / 10) * 10}%`,
        }}
        data-has-hp={hive.hp > 0}
      >
        {hive.hp > 0 && hive.hp}
      </div>
      <Button
        className="hive-button"
        onClick={handleClick}
        variant="icon"
        icon={image[hive.type]}
      />
    </div>
  );
};

export default HiveButton;
