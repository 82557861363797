import React, { useMemo } from 'react';
import './styles.scss';
import Button from '../../../../components/Button';
import coinImage from '../../../../assets/images/coin.png';
import { useCoinButtonControl } from './hooks';

export interface CoinButtonProps {
  onGetCoin: () => void;
  canGetCoin: boolean;
  availableDate?: Date;
}

const CoinButton: React.FC<CoinButtonProps> = ({
  onGetCoin,
  canGetCoin,
  availableDate,
}) => {
  const {
    availableDateState,
    coinsConfig,
    isDisabled,
    isCoinsConfigLoading,
    handleButtonClick,
  } = useCoinButtonControl({
    onGetCoin,
    canGetCoin,
    availableDate,
  });

  const hasRobotBooster = useMemo(
    () => (coinsConfig as any)?.hasRobotBooster,
    [coinsConfig],
  );

  return (
    <div className="coin-button__wrapper">
      <Button
        className="coin-button"
        onClick={handleButtonClick}
        disabled={isCoinsConfigLoading || isDisabled || hasRobotBooster}
      >
        <div className="coin-button__text-wrapper">
          <div className="coin-button__info">
            {hasRobotBooster ? 'Auto claim' : 'Claim'}{' '}
            {availableDateState?.coinsFarmedCount?.toFixed(3) || 0}
            <img
              src={coinImage}
              className="coin-button__collect-icon"
              alt="coin"
            />{' '}
          </div>
          <div className="coin-button__additional-info">
            {`(${availableDateState?.progressPercent?.toFixed(3)}%, ${availableDateState?.progressPercent === 100 ? 0 : (coinsConfig as any)?.coinsPerHour} coin/h)`}
          </div>
        </div>
      </Button>
    </div>
  );
};

export default CoinButton;
