import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CoinsState {
  coins: number;
  tonCoins: number;
  hiveCoins: number;
}

const initialState: CoinsState = {
  coins: 0,
  tonCoins: 0,
  hiveCoins: 0,
};

const authSlice = createSlice({
  name: 'coins',
  initialState,
  reducers: {
    setCoins(state, action: PayloadAction<number | null>) {
      state.coins = action.payload || 0;
    },
    setTonCoins(state, action: PayloadAction<number | null>) {
      state.tonCoins = action.payload || 0;
    },
    setHiveCoins(state, action: PayloadAction<number | null>) {
      state.hiveCoins = action.payload || 0;
    },
  },
});

export const { setCoins, setTonCoins, setHiveCoins } = authSlice.actions;

export default authSlice.reducer;
