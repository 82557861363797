import dayjs from 'dayjs';
import { CalculateRemainingTimeReturnData } from './types';

export const calculateGameRemainingTime = (
  availableDate?: Date | string,
): CalculateRemainingTimeReturnData => {
  const now = dayjs();
  const availableDateTime = dayjs(availableDate);

  // Вычисляем оставшееся время в секундах
  let secondsRemaining = availableDateTime.diff(now, 'seconds');

  // Если время уже прошло, устанавливаем оставшееся время на 0
  if (secondsRemaining < 0) {
    secondsRemaining = 0;
  }

  const hours = Math.floor(secondsRemaining / 3600);
  const minutes = Math.floor((secondsRemaining % 3600) / 60);
  const seconds = secondsRemaining % 60;

  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return {
    availableDate: availableDateTime.toDate(),
    remainingTime: formattedTime,
  };
};
