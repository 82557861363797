import React from 'react';
import './styles.scss';
import { BoosterData, BoosterType } from '../../../../types/Shop';
import { PriceType } from '../../../../types/Coin';
import coinImage from '../../../../assets/images/coin.png';
import honeyCoinImage from '../../../../assets/images/honey-coin.webp';
import AcceleratorImage from '../../../../assets/images/accelerator-booster.png';
import VolumeImage from '../../../../assets/images/volume-booster.png';
import RobotImage from '../../../../assets/images/robot-booster.png';

interface Props {
  onClick: (booster: BoosterData) => void;
  booster: BoosterData;
  disabled: boolean;
}

const Booster: React.FC<Props> = ({ booster, onClick, disabled }) => {
  const { type, title, subtitle, price, priceType } = booster || {};

  const mainIcon: Record<BoosterType, string> = {
    accelerator: AcceleratorImage,
    volume: VolumeImage,
    robot: RobotImage,
  };

  const priceIcon: Record<PriceType, string> = {
    coins: coinImage,
    TON: honeyCoinImage,
  };

  return (
    <div
      data-disabled={disabled}
      className="booster__wrapper"
      onClick={() => onClick(booster)}
    >
      <img
        src={mainIcon?.[type]}
        className="booster__icon"
        alt="booster-icon"
      />
      <div className="booster__description">
        <h3 className="booster__title">{title}</h3>
        <p className="booster__subtitle">{subtitle}</p>
      </div>
      <div className="booster__price">
        {!booster.isLastStage ? (
          <>
            <img src={priceIcon?.[priceType]} alt="booster-price-icon" />
            <p>{price || 0}</p>
          </>
        ) : (
          <p>full</p>
        )}
      </div>
    </div>
  );
};

export default Booster;
