import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getCoinsStatus } from '../../services/coin';
import { CoinData } from '../../types/Coin';
import {
  buyBooster,
  getActiveBoosters,
  getBoosters,
} from '../../services/shop';
import { BoosterData } from '../../types/Shop';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setCoins, setTonCoins } from '../../store/coinsSlice';

export const useShopControl = () => {
  const dispatch = useDispatch();
  const coins = useSelector((state: RootState) => state.coins.coins);
  const tonCoins = useSelector((state: RootState) => state.coins.tonCoins);
  const [selectedBooster, setSelectedBooster] = useState<BoosterData | null>(
    null,
  );

  const {
    refetch: refetchCoinsStatus,
    isLoading: isCoinsStatusLoading,
    error: coinsStatusError,
    isError: isCoinsStatusError,
  } = useQuery('coinStatus', getCoinsStatus, {
    retryDelay: 10000,
    enabled: true,
    retry: false,
    onSuccess: (data: CoinData) => {
      dispatch(setCoins(data?.currentCoinsCount));
      dispatch(setTonCoins(Number(data?.currentTonCount)));
    },
  });

  const {
    data: boostersList,
    isLoading: isBoostersListLoading,
    error: boostersListStatusError,
    isError: isBoostersListError,
  } = useQuery('getBoosters', getBoosters, {
    enabled: true,
    retry: false,
  });

  const {
    refetch: refetchActiveBoostersList,
    data: activeBoostersList,
    isLoading: isActiveBoostersLoading,
  } = useQuery('getActiveBoosters', getActiveBoosters, {
    enabled: true,
    retry: false,
  });

  const { mutate: handleBuyBooster, isLoading: isBoosterBuyFetching } =
    useMutation(buyBooster, {
      onSuccess: () => {
        refetchCoinsStatus();
        refetchActiveBoostersList();
      },
    });

  const resultSellBoosters = useMemo(() => {
    return (
      (Array.isArray(boostersList) &&
        boostersList?.reduce((acc: any[], booster: BoosterData) => {
          const isVisible =
            Array.isArray(activeBoostersList) &&
            activeBoostersList.some(
              (activeBooster) =>
                activeBooster?.type === booster?.type &&
                ((activeBooster?.stage === booster?.stage &&
                  booster?.isLastStage) ||
                  activeBooster?.stage + 1 === booster.stage),
            );
          if (isVisible) {
            return [
              ...acc,
              {
                ...booster,
                isLastStage: activeBoostersList.some(
                  (activeBooster) =>
                    activeBooster?.type === booster?.type &&
                    activeBooster?.stage === booster?.stage &&
                    booster?.isLastStage,
                ),
              },
            ];
          }
          return acc;
        }, [])) ||
      []
    );
  }, [activeBoostersList, boostersList]);

  const handleBoosterBuyClick = (booster: BoosterData) => {
    handleBuyBooster(booster as any);
  };

  const handleConfirmClick = () => {
    selectedBooster && handleBoosterBuyClick(selectedBooster);
    setSelectedBooster(null);
  };

  const handleRejectClick = () => {
    setSelectedBooster(null);
  };

  const handleBoosterClick = (booster: BoosterData) => {
    setSelectedBooster(booster);
  };

  return {
    coins,
    tonCoins,
    resultSellBoosters,
    selectedBooster,
    handleBoosterBuyClick,
    handleBoosterClick,
    handleConfirmClick,
    handleRejectClick,
    isBoosterBuyFetching,
    isCoinsStatusLoading,
    isBoostersListLoading,
    isActiveBoostersLoading,
    isCoinsStatusError,
    isBoostersListError,
    coinsStatusError,
    boostersListStatusError,
  };
};
