import React, { useEffect, useState } from 'react';
import './styles.scss';
import Modal from '../../../../components/Modal';
import dailyRewardsImage from '../../../../assets/images/dailyRewards.png';
import Button from '../../../../components/Button';
import { useMutation } from 'react-query';
import { getDailyGameBonus } from '../../../../services/coin';
import { useNavigate } from 'react-router-dom';

interface Props {
  hiveCoins: number;
  maxHiveCoins: number;
  isSuccess: boolean;
}

const SuccessModal: React.FC<Props> = ({
  hiveCoins,
  isSuccess,
  maxHiveCoins,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const { mutate: handleGetGameReward } = useMutation(getDailyGameBonus, {
    onSuccess: () => {
      closeModal();
      navigate('/', { replace: true });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      return openModal();
    }
    closeModal();
  }, [isSuccess]);

  if (isModalOpen) {
    return (
      <Modal
        className="success-modal"
        isOpen={isModalOpen}
        onClose={closeModal}
        isOutsideDisabled
      >
        <h2 className="success-modal__title">
          You have collected all the honey
        </h2>
        <div className="success-modal__main-icon-wrapper">
          <img
            src={dailyRewardsImage}
            className="success-modal__main-icon"
            alt="success-modal"
          />
        </div>
        <p className="success-modal__reward">
          Reward: {hiveCoins || 0} / {maxHiveCoins}
        </p>

        <div className="success-modal__buttons">
          <Button
            variant="blue-black"
            onClick={() => {
              handleGetGameReward(hiveCoins);
            }}
          >
            Reward {hiveCoins} BearCoins
          </Button>
        </div>
      </Modal>
    );
  }
  return null;
};

export default SuccessModal;
