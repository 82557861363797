import React from 'react';
import './styles.scss';
import hiveFullImage from '../../../../assets/images/hive-full.webp';

const Header: React.FC<{
  coins: number;
  maxHiveCoins: number;
}> = ({ coins = 0, maxHiveCoins }) => {
  return (
    <header className="game-header__header">
      <div className="game-header__wrapper">
        <div className="game-header__background">
          <div className="game-header__image">
            <img src={hiveFullImage} className="game-header__coin" alt="coin" />
            <div className="game-header__coin-effect"></div>
          </div>
          <p className="game-header__count">
            {coins} / {maxHiveCoins}
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
