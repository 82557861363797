import React from 'react';
import './styles.scss';
import Button from '../../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useGameButtonControl } from '../../../../pages/Main/components/HoneyGameButton/hooks';
import lockImage from '../../../../assets/images/lock.png';

const HoneyGameButton: React.FC = () => {
  const navigate = useNavigate();
  const { availableDateState, isDisabled } = useGameButtonControl();

  return (
    <Button
      variant="blue-black"
      onClick={() => navigate('/game')}
      className="main__game-button"
      disabled={isDisabled}
    >
      Honey game{' '}
      {isDisabled && (
        <div className="main__lock">
          <img
            src={lockImage}
            className="coin-button__collect-icon"
            alt="coin"
          />{' '}
          {availableDateState?.remainingTime}{' '}
        </div>
      )}
    </Button>
  );
};

export default HoneyGameButton;
