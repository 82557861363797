import {
  HiveButtonTypes,
  HiveData,
} from '../../pages/Game/components/HiveButton';

export const HIVE_DATA: HiveData[] = [
  {
    id: 1,
    type: HiveButtonTypes.Full,
    position: {
      x: -50,
      y: -10,
    },
    scale: 0.35,
    hp: 100,
    reward: 1,
  },
  {
    id: 2,
    type: HiveButtonTypes.Full,
    position: {
      x: 150,
      y: 20,
    },
    scale: 0.3,
    hp: 100,
    reward: 1,
  },
  {
    id: 3,
    type: HiveButtonTypes.Full,
    position: {
      x: 178,
      y: 350,
    },
    scale: 0.6,
    hp: 100,
    reward: 1,
  },
  {
    id: 4,
    type: HiveButtonTypes.Full,
    position: {
      x: 190,
      y: 100,
    },
    scale: 0.25,
    hp: 100,
    reward: 1,
  },
];
