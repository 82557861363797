import React, { useState } from 'react';
import './styles.scss';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { TonPacksData } from '@/types/Wallet';
import TONImage from '../../../../assets/images/TON.png';
import HoneyCoinImage from '../../../../assets/images/honey-coin.webp';

interface Props {
  isOpen: boolean;
  isFetching: boolean;
  onConfirm: (pack: TonPacksData) => void;
  onReject: () => void;
  packs: TonPacksData[];
}

const ConfirmModal: React.FC<Props> = ({
  isOpen,
  isFetching,
  onConfirm,
  onReject,
  packs,
}) => {
  const [selectedPack, setSelectedPack] = useState<number>(packs[0]?.id || 0);

  const handlePackChange = (pack: TonPacksData) => {
    setSelectedPack(pack.id);
  };

  const selectedPackData = packs.find((pack) => pack.id === selectedPack);

  if (isOpen) {
    return (
      <Modal isOpen={isOpen} onClose={onReject} className="modal_wallet">
        <img className="modal__logo" src={HoneyCoinImage} alt="TON" />
        <div className="pack__selector">
          {packs?.map((pack) => (
            <div
              onClick={() => handlePackChange(pack)}
              key={pack.id}
              className="pack__option"
            >
              <input
                type="radio"
                id={`pack-${pack.id}`}
                name="pack"
                value={pack.id}
                checked={selectedPack === pack.id}
                data-selected={selectedPack === pack.id}
              />
              <label htmlFor={`pack-${pack.id}`}>
                {` `}
                <img src={TONImage} alt="TON" />
                {` `}
                {pack.price}
                {` TON `}
                {` → `}
                {` `}
                <img src={HoneyCoinImage} alt="TON" />
                {` `}
                {pack.count}
                {` HONEY COIN`}
              </label>
            </div>
          ))}

          <div className="modal__buttons">
            <Button onClick={onReject} disabled={isFetching}>
              Cancel
            </Button>
            <Button
              onClick={() => onConfirm(selectedPackData!)}
              disabled={isFetching}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
  return null;
};

export default ConfirmModal;
