import React, { useState } from 'react';
import './styles.scss';
import Header from '../../components/Header';
import CoinButton from './components/CoinButton';
import Menu from '../../components/Menu';
import { CoinData } from '../../types/Coin';
import { useQuery } from 'react-query';
import { getCoinsStatus, getDailyBonus, payCoins } from '../../services/coin';
import Notification from '../../components/Notification';
import dayjs from 'dayjs';
import AppLayout from '../../components/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import DailyBonusModal from './components/DailyBonusModal';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { setCoins, setTonCoins } from '../../store/coinsSlice';
import HoneyGameButton from './components/HoneyGameButton';

const MainPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const coins = useSelector((state: RootState) => state.coins.coins);
  const tonCoins = useSelector((state: RootState) => state.coins.tonCoins);

  const [canGetCoin, setCanGetCoin] = useState(false);

  const {
    isLoading: isCoinsStatusLoading,
    isError: isCoinsStatusError,
    data: coinsStatusData,
    error: coinsStatusError,
    refetch: coinsStatusRefetch,
  } = useQuery('coinStatus', getCoinsStatus, {
    retryDelay: 10000,
    enabled: true,
    keepPreviousData: true,
    onSuccess: (data: CoinData) => {
      const currentDate = dayjs();
      const nextActivationDate = dayjs(data?.nextCoinButtonActivationDate);
      dispatch(setCoins(data?.currentCoinsCount));
      dispatch(setTonCoins(Number(data?.currentTonCount)));
      setCanGetCoin(currentDate.diff(nextActivationDate) > 0);
    },
  });

  const {
    isLoading: isCoinsPayLoading,
    isError: isCoinsPayError,
    error: coinsPayError,
    refetch: coinsPayRefetch,
  } = useQuery('payCoin', payCoins, {
    enabled: false,
    retry: false,
    onSuccess: (data) => coinsStatusRefetch(),
  });

  const { isLoading: isDailyBonusLoading, data: dailyBonusData } = useQuery(
    'getDailyBonus',
    getDailyBonus,
    {
      enabled: true,
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      onSuccess: (data) => coinsStatusRefetch(),
    },
  );

  return (
    <AppLayout>
      <Header coins={coins} tonCoins={tonCoins} />
      <div className="main__bottom">
        <CoinButton
          onGetCoin={coinsPayRefetch}
          canGetCoin={canGetCoin}
          availableDate={coinsStatusData?.nextCoinButtonActivationDate}
        />
        <HoneyGameButton />
        <Button
          onClick={() => navigate('/roadmap')}
          className="main__roadmap-button"
        >
          Roadmap
        </Button>
        <Menu />
      </div>
      <Notification
        isOpen={isCoinsStatusError || isCoinsPayError}
        type="error"
        title="Произошла ошибка"
        text={
          coinsStatusError
            ? (coinsStatusError as any)?.text || (coinsPayError as any)?.text
            : 'Неизвестная ошибка'
        }
      />
      <DailyBonusModal dailyBonusData={dailyBonusData as any} />
    </AppLayout>
  );
};

export default MainPage;
