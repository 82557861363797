import React, { useState } from 'react';
import './styles.scss';
import AppLayout from '../../components/AppLayout';
import BackButton from '../../components/BackButton';
import walletIcon from '../../assets/images/wallet.png';
import Button from '../../components/Button';
import {
  TonConnectButton,
  useTonConnectUI,
  useTonAddress,
} from '@tonconnect/ui-react';
import { useMutation } from 'react-query';
import { handlePayment } from '../../services/wallet';
import { beginCell, toNano } from '@ton/ton';
import { v4 as uuidv4 } from 'uuid';
import ConfirmModal from './components/ConfirmModal';
import { TonPacksData } from '../../types/Wallet';
import honeyCoinImage from '../../assets/images/honey-coin.webp'; // Импортируем uuid

const WalletPage: React.FC = () => {
  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);
  const developerWalletAddress = String(
    process.env.REACT_APP_SERVER_TON_ADDRESS,
  );
  const [tonConnectUI] = useTonConnectUI();
  const [isAlertShow, setIsAlertShow] = useState(false);

  const { mutate: handlePaymentRequest, isLoading: isPaymentProcessing } =
    useMutation(
      async (amount: bigint) => {
        if (!developerWalletAddress) {
          throw new Error('Wallet address is not available');
        }

        const uniqueHash = uuidv4();

        const body = beginCell()
          .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
          .storeStringTail(uniqueHash) // write our text comment
          .endCell();

        await handlePayment({
          developerWalletAddress,
          uniqueHash,
          value: String(amount),
        });
        setIsAlertShow(true);

        await tonConnectUI.sendTransaction({
          validUntil: Math.floor(Date.now() / 1000) + 60,
          messages: [
            {
              address: developerWalletAddress,
              amount: String(amount),
              payload: body.toBoc().toString('base64'),
            },
          ],
        });

        return { uniqueHash, amount };
      },
      {
        onSuccess: () => {
          console.log('success');
        },
        onError: (error) => {
          console.error(error);
        },
      },
    );

  const [selectedPack, setSelectedPack] = useState(false);

  const handlePaymentClick = (count: number) => {
    setSelectedPack(false);
    handlePaymentRequest(toNano(count));
  };

  const handleConfirmClick = (pack: TonPacksData) => {
    handlePaymentClick(pack?.count);
  };

  const handleRejectClick = () => {
    setSelectedPack(false);
  };

  const handlePaymentButtonClick = () => {
    setSelectedPack(true);
  };

  return (
    <AppLayout>
      <BackButton />
      <main className="wallet__main">
        <div className="wallet__top-wrapper">
          <img src={walletIcon} className="wallet__image" alt="wallet" />
          <h2 className="wallet__title">Connect your wallet for reward</h2>
          {isAlertShow && (
            <p className="wallet__subtitle">
              Payment is being processed. It may take a few minutes for the
              funds to appear.
            </p>
          )}
          <TonConnectButton className="wallet__button" />
          {userFriendlyAddress && (
            <Button
              variant="blue-black"
              className="wallet__button"
              onClick={handlePaymentButtonClick}
              disabled={isPaymentProcessing || !tonConnectUI?.connected}
            >
              <div>
                <span>
                  {isPaymentProcessing ? 'Processing...' : 'Buy honey coin'}
                </span>
                {!isPaymentProcessing && (
                  <img
                    src={honeyCoinImage}
                    className="coin-container__coin"
                    alt="coin"
                  />
                )}
              </div>
            </Button>
          )}
        </div>
        <ConfirmModal
          isOpen={!!selectedPack}
          isFetching={isPaymentProcessing}
          onConfirm={handleConfirmClick}
          onReject={handleRejectClick}
          packs={
            [
              { id: 0, count: 1, price: 1 },
              { id: 2, count: 2, price: 2 },
              { id: 1, count: 5, price: 5 },
              { id: 3, count: 10, price: 10 },
            ] as TonPacksData[]
          }
        />
      </main>
    </AppLayout>
  );
};

export default WalletPage;
