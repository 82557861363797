import axios from './api/axios';
import { CoinData, CoinsConfigData, DailyBonusData } from '../types/Coin';
import { AxiosResponse } from 'axios';

export const getCoinsStatus = async (): Promise<AxiosResponse<CoinData>> => {
  const response = await axios.get<CoinData>('/coin/count');
  return response;
};

export const payCoins = async (): Promise<AxiosResponse<CoinData>> => {
  const response = await axios.put<CoinData>('/coin/');
  return response;
};

export const getDailyGameBonus = async (
  count: number,
): Promise<AxiosResponse<CoinData>> => {
  const response = await axios.post<CoinData>('/coin/game/bonus', {
    count,
  });
  return response;
};

export const getCoinsConfig = async (): Promise<
  AxiosResponse<CoinsConfigData>
> => {
  const response = await axios.get<CoinsConfigData>('/coin/config');
  return response;
};

export const getDailyBonus = async (): Promise<
  AxiosResponse<DailyBonusData>
> => {
  const response = await axios.post<DailyBonusData>('/coin/bonus');
  return response;
};

export const quickLogin = async (
  token: string,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post<any>('/users/quick-login', {
    quickLoginId: token,
  });
  return response;
};
