import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getCoinsConfig } from '../../../../services/coin';
import dayjs from 'dayjs';
import { calculateGameRemainingTime } from './helpers';
import { CalculateRemainingTimeReturnData } from './types';

export const useGameButtonControl = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const { isLoading: isCoinsConfigLoading, data: coinsConfig } = useQuery(
    'getCoinsConfig',
    getCoinsConfig,
    {
      enabled: true,
      retry: false,
    },
  );

  const [availableDateState, setAvailableDateState] =
    useState<CalculateRemainingTimeReturnData | null>(null);

  useEffect(() => {
    if (!coinsConfig) {
      return;
    }

    let timeout: any = null;

    const updateTime = () => {
      const calculatedRemainingTime = calculateGameRemainingTime(
        dayjs((coinsConfig as any)?.lastGameBonusAddition)
          .add(1, 'day')
          .toDate(),
      );

      setAvailableDateState(calculatedRemainingTime);

      // Устанавливаем isDisabled в true, если оставшееся время больше 0
      setIsDisabled(calculatedRemainingTime.remainingTime !== '00:00:00');

      timeout = setTimeout(updateTime, 1000);
    };

    updateTime();

    return () => timeout && clearTimeout(timeout);
  }, [coinsConfig]);

  return {
    availableDateState,
    coinsConfig,
    isDisabled,
    isCoinsConfigLoading,
  };
};
