import { Stage } from './types';

export enum StageStatus {
  complete = 'complete',
  soon = 'soon',
}

export const STAGES: Stage[] = [
  {
    title: 'Basic game',
    status: StageStatus.complete,
  },
  {
    title: 'Referal',
    status: StageStatus.complete,
  },
  {
    title: 'Daily rewards',
    status: StageStatus.complete,
  },
  {
    title: 'Store',
    status: StageStatus.complete,
  },
  {
    title: 'Earn',
    status: StageStatus.complete,
  },
  {
    title: 'Wallet',
    status: StageStatus.complete,
  },
  {
    title: 'Presale',
    status: StageStatus.soon,
  },
  {
    title: 'Airdrop',
    status: StageStatus.soon,
  },
];
